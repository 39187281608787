import { Tab } from "bootstrap";
import React, { useContext, useEffect } from "react";
import { Tabs } from "react-bootstrap";
import FinancialStatement from "../components/dashboard/FinancialStatement";
import PersonalInformation from "../components/dashboard/PersonalInformation";
import Report from "../components/dashboard/Report";
import Loading from "../components/Loading";
import UserContext from "../context/UserContext";

const DashboardPage = () => {
  const { user } = useContext(UserContext);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [user]);
  return (
    <div className="container relative block-page">
      {user ? (
        <Tabs defaultActiveKey="state" id="dashboard">
          <Tab eventKey="personalInformation" title="Personal information">
            <PersonalInformation />
          </Tab>
          <Tab eventKey="state" title="Financial statement">
            <FinancialStatement />
          </Tab>
          <Tab
            eventKey="reports"
            title="Report"
            disabled={user.reports.length === 0}
          >
            <Report />
          </Tab>
        </Tabs>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default DashboardPage;
