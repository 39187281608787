import React, { useContext, useState } from "react";
import { Form, Modal, Button, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import UserContext from "../../context/UserContext";
import UserAPI from "../../services/UserAPI";

const PropertyDelete = ({ show, setShow, id }) => {
  const { user, setUser } = useContext(UserContext);

  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setShow({ name: null, id: null });
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    const originalProperties = [...user.properties];
    const newProperties = user.properties.filter(
      (property) => property["@id"] !== id
    );
    setUser({
      ...user,
      properties: newProperties,
    });

    try {
      await UserAPI.delete_properties(user.id, newProperties);
      toast.success("Your property has been successfully deleted !");
      setShow({ name: null, id: null });
    } catch (error) {
      setLoading(false);
      toast.warning(
        "An error has occurred, please contact your administrator and specify the action you wish to take."
      );
      setUser({
        ...user,
        properties: originalProperties,
      });
    }
  };
  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Delete property</Modal.Title>
        </Modal.Header>
        <Modal.Body>You will delete the property</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" type="submit" disabled={loading}>
            {loading && (
              <Spinner
                style={{
                  marginRight: "5px",
                  marginBottom: "2px",
                }}
                size="sm"
                animation="border"
              />
            )}
            Delete
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default PropertyDelete;
