import React, { useContext, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { toast } from "react-toastify";
import UserContext from "../../context/UserContext";
import UserAPI from "../../services/UserAPI";
import Field from "../form/Field";

const PropertyForm = ({ show, setShow }) => {
  const { user, setUser } = useContext(UserContext);
  const [property, setProperty] = useState({
    name: "",
    marketValue: "",
    income: "",
    mortgage: "",
    interest: "",
    amortization: "",
    operatingExpense: "",
  });
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({});

  const handleClose = () => {
    setProperty({
      name: "",
      marketValue: "",
      income: "",
      mortgage: "",
      interest: "",
      amortization: "",
      operatingExpense: "",
    });
    setErrors({});
    setShow(false);
  };

  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setProperty({ ...property, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    const apiErrors = {};
    try {
      const { properties } = await UserAPI.add_properties(
        user.id,
        property,
        user.properties
      );
      toast.success(" Your property has been successfully added !");
      setLoading(false);
      setUser({ ...user, properties });
      handleClose();
    } catch (error) {
      setLoading(false);
      const { violations } = error.response.data;
      if (violations) {
        violations.forEach((violation) => {
          apiErrors[violation.propertyPath.split(".")[1]] = violation.message;
        });
        setErrors(apiErrors);
      }
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Add a property</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <Field
                id="name"
                label="Property name *"
                value={property.name}
                onChange={handleChange}
                error={errors.name}
              />
            </Col>
            <Col md={4}>
              <Form.Label htmlFor="basic-url">
                {"Purchasing price ($CA) *"}
              </Form.Label>
              <CurrencyFormat
                className="form-control"
                value={property.marketValue}
                thousandSeparator={true}
                onValueChange={(values) => {
                  const { value } = values;
                  setProperty({ ...property, marketValue: value });
                  setErrors({ ...errors, marketValue: "" });
                }}
              />

              {errors.marketValue && (
                <p className="f-12 text-danger">{errors.marketValue}</p>
              )}
            </Col>
            <Col md={4}>
              <Form.Label htmlFor="basic-url">{"Mortgage ($CA) *"}</Form.Label>
              <CurrencyFormat
                className="form-control"
                value={property.mortgage}
                thousandSeparator={true}
                onValueChange={(values) => {
                  const { value } = values;
                  setProperty({ ...property, mortgage: value });
                  setErrors({ ...errors, mortgage: "" });
                }}
              />
              {errors.mortgage && (
                <p className="f-12 text-danger">{errors.mortgage}</p>
              )}
            </Col>
            <Col md={4}>
              <Form.Label htmlFor="basic-url">{"Interest (%) *"}</Form.Label>
              <CurrencyFormat
                className="form-control"
                aria-label="Interest"
                value={property.interest}
                thousandSeparator={true}
                onValueChange={(values) => {
                  const { value } = values;
                  setProperty({ ...property, interest: value });
                  setErrors({ ...errors, interest: "" });
                }}
              />
              {errors.interest && (
                <p className="f-12 text-danger">{errors.interest}</p>
              )}
            </Col>
            <Col md={4}>
              <Form.Label htmlFor="basic-url">{"Income ($CA) *"}</Form.Label>
              <CurrencyFormat
                className="form-control"
                value={property.income}
                thousandSeparator={true}
                onValueChange={(values) => {
                  const { value } = values;
                  setProperty({ ...property, income: value });
                  setErrors({ ...errors, income: "" });
                }}
              />
              {errors.income && (
                <p className="f-12 text-danger">{errors.income}</p>
              )}
            </Col>
            <Col md={4}>
              <Form.Label htmlFor="basic-url">
                {"Operating Expense ($CA) *"}
              </Form.Label>
              <CurrencyFormat
                className="form-control"
                value={property.operatingExpense}
                thousandSeparator={true}
                onValueChange={(values) => {
                  const { value } = values;
                  setProperty({ ...property, operatingExpense: value });
                  setErrors({ ...errors, operatingExpense: "" });
                }}
              />
              {errors.operatingExpense && (
                <p className="f-12 text-danger">{errors.operatingExpense}</p>
              )}
            </Col>
            <Col md={4}>
              <Form.Label htmlFor="basic-url">
                {"Amortization (Years) *"}
              </Form.Label>
              <CurrencyFormat
                className="form-control"
                value={property.amortization}
                thousandSeparator={true}
                onValueChange={(values) => {
                  const { value } = values;
                  setProperty({ ...property, amortization: value });
                  setErrors({ ...errors, amortization: "" });
                }}
              />
              {errors.amortization && (
                <p className="f-12 text-danger">{errors.amortization}</p>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" disabled={loading}>
            {loading && (
              <Spinner
                style={{
                  marginRight: "5px",
                  marginBottom: "2px",
                }}
                size="sm"
                animation="border"
              />
            )}
            Add Property
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default PropertyForm;
