import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import Divider from "../components/Divider";
import Field from "../components/form/Field";
import Title from "../components/sign/Title";
import AuthContext from "../context/AuthContext";
import AuthAPI from "../services/AuthAPI";

const SignInPage = ({ history }) => {
  const { setIsAuthenticated } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);

  const [creadentials, setCredentials] = useState({
    username: "",
    password: "",
  });

  const [error, setError] = useState("");

  // Gestion des champs
  const handleChange = ({ currentTarget }) => {
    const { value, name } = currentTarget;
    setError("");
    setCredentials({ ...creadentials, [name]: value });
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    try {
      setIsAuthenticated(await AuthAPI.authenticate(creadentials));
      toast.success("You are successfully connected !");
      history.replace("/");
    } catch (error) {
      setLoading(false);
      setError("An error has occurred, please check your information");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="bg-gradient-primary ">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10 col-lg-12 col-md-9">
            <div className="card o-hidden border-0 shadow-lg my-100">
              <div className="card-body p-5">
                <Title title="Sign In" />
                <Form className="user" onSubmit={handleSubmit}>
                  <Field
                    id="username"
                    value={creadentials.username}
                    placeholder="Enter email"
                    onChange={handleChange}
                    error={error}
                  />
                  <Field
                    id="password"
                    value={creadentials.password}
                    type="password"
                    placeholder="Password"
                    onChange={handleChange}
                    error={error}
                  />
                  <Button variant="primary" type="submit" disabled={loading}>
                    {loading && (
                      <Spinner
                        style={{
                          marginRight: "5px",
                          marginBottom: "2px",
                        }}
                        size="sm"
                        animation="border"
                      />
                    )}
                    Submit
                  </Button>
                </Form>
                <Divider />
                <div className="text-center">
                  <NavLink className="small" to="">
                    Forgot Password?
                  </NavLink>
                </div>
                <div className="text-center">
                  <NavLink className="small" to="/sign-up">
                    Create an Account!
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInPage;
