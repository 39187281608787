import Axios from "axios";
import { PROPERTIES_URL } from "../config";

function edit_property(property) {
  let id = property.id;
  let values = {
    name: property.name,
    marketValue: property.marketValue ? parseFloat(property.marketValue) : null,
    income: property.income ? parseFloat(property.income) : null,
    mortgage: property.mortgage ? parseFloat(property.mortgage) : null,
    interest: property.interest ? parseFloat(property.interest) : null,
    amortization: property.amortization
      ? parseFloat(property.amortization)
      : null,
    operatingExpense: property.operatingExpense
      ? parseFloat(property.operatingExpense)
      : null,
  };
  return Axios.put(PROPERTIES_URL + "/" + id, values).then(
    (response) => response.data
  );
}

export default {
  edit_property,
};
