import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { FaEnvelope, FaKey } from "react-icons/fa";
import UserContext from "../../context/UserContext";
import Card from "../card/Card";
import CardBody from "../card/CardBody";
import Divider from "../Divider";

const PersonalInformation = () => {
  const { user } = useContext(UserContext);

  return (
    <Card>
      <CardBody>
        <h3 className="text-center mb-0">
          {user.firstName} {user.lastName}
        </h3>

        <Divider />
        <Row className="mt-5 mx-0 align-items-center">
          <Col md={3}>
            <h6 className="text-uppercase">login information :</h6>
          </Col>
          <Col md={9}>
            <div className="block-line mb-0">
              <FaEnvelope />
              <p className="mb-0">{user.email}</p>
            </div>
            <div className="block-line mb-0">
              <FaKey />
              <p className="mb-0">********</p>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default PersonalInformation;
