import React from "react";
import { Card, Table } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { dateFormat } from "../../functions/Format";
import CardBody from "../card/CardBody";

const RenderReports = ({ reports }) => {
  return (
    <div className="container mt-5 mb-2">
      <Card>
        <CardBody>
          <Table>
            <thead>
              <tr>
                <th>#</th>
                <th>Completed At</th>
                <th className="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {reports.map((report, i) => (
                <tr key={report["@id"]}>
                  <td>{i + 1}</td>
                  <td>{dateFormat(report.completedAt.date)}</td>
                  <td className="text-center">
                    <NavLink
                      to={{
                        pathname: "/view-report",
                        state: report,
                      }}
                      className="btn btn-change btn-primary"
                    >
                      View
                    </NavLink>
                    <NavLink
                      to="/view-report"
                      className="btn btn-change btn-success"
                    >
                      Download
                    </NavLink>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </div>
  );
};

export default RenderReports;
