import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <div className="bg-primary py-4 footer-container">
      <p className="small text-white text-center m-0 text-muted">
        Copyright 2018 Inomnem Inc.
      </p>
      <ul className="justify-content-center nav ">
        <li className="mb-2 mx-1">
          <NavLink className="text-white small" to="">
            Confidentiality terms &amp; Conditions
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Footer;
