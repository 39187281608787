import React, { useContext, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { toast } from "react-toastify";
import UserContext from "../../context/UserContext";
import FinancialStatementAPI from "../../services/FinancialStatementAPI";

const FinancialStatementEditForm = ({ show, setShow, data }) => {
  const { user, setUser } = useContext(UserContext);
  const [state, setState] = useState({
    investedAmount: data.investedAmount,
    income: data.income,
    otherIncome: data.otherIncome,
    debt: data.debt,
    interest: data.interest,
    celi: data.celi,
    celiP: data.celiP,
    reer: data.reer,
    reerP: data.reerP,
    noReer: data.noReer,
    noReerP: data.noReerP,
    companyValue: data.companyValue,
    companyValueP: data.companyValueP,
    managementValue: data.managementValue,
    managementValueP: data.managementValueP,
  });
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({});

  const handleClose = () => {
    setErrors({});
    setShow(false);
  };
  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    const apiErrors = {};
    try {
      const financialStatements = await FinancialStatementAPI.edit(
        data.id,
        state
      );
      toast.success(
        "You have successfully filled in your information, a consultant will contact you shortly !"
      );
      setUser({ ...user, financialStatements: financialStatements });
      setState({});
      setShow(false);
      setLoading(false);
    } catch (error) {
      toast.warning(" Please check the information provided !");
      setLoading(false);
      const { violations } = error.response.data;
      if (violations) {
        violations.forEach((violation) => {
          apiErrors[violation.propertyPath.split(".")[1]] = violation.message;
        });
        setErrors(apiErrors);
      }
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit financial statement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <Form.Label htmlFor="basic-url">
                {"Invested Amount ($CA) *"}
              </Form.Label>
              <CurrencyFormat
                className="form-control"
                value={state.investedAmount}
                thousandSeparator={true}
                onValueChange={(values) => {
                  const { value } = values;
                  setState({ ...state, investedAmount: value });
                  setErrors({ ...errors, investedAmount: "" });
                }}
              />
              {errors.investedAmount && (
                <p className="f-12 text-danger">{errors.investedAmount}</p>
              )}
            </Col>
            <Col md={6}>
              <Form.Label htmlFor="basic-url">{"Income ($CA)"}</Form.Label>
              <CurrencyFormat
                className="form-control"
                value={state.income}
                thousandSeparator={true}
                onValueChange={(values) => {
                  const { value } = values;
                  setState({ ...state, income: value });
                  setErrors({ ...errors, income: "" });
                }}
              />
              {errors.income && (
                <p className="f-12 text-danger">{errors.income}</p>
              )}
            </Col>
            <Col md={6}>
              <Form.Label htmlFor="basic-url">
                {"Other Income ($CA)"}
              </Form.Label>
              <CurrencyFormat
                className="form-control"
                value={state.otherIncome}
                thousandSeparator={true}
                onValueChange={(values) => {
                  const { value } = values;
                  setState({ ...state, otherIncome: value });
                  setErrors({ ...errors, otherIncome: "" });
                }}
              />
              {errors.otherIncome && (
                <p className="f-12 text-danger">{errors.otherIncome}</p>
              )}
            </Col>
            <Col md={6}>
              <Form.Label htmlFor="basic-url">{"Debt ($CA)"}</Form.Label>
              <CurrencyFormat
                className="form-control"
                value={state.debt}
                thousandSeparator={true}
                onValueChange={(values) => {
                  const { value } = values;
                  setState({ ...state, debt: value });
                  setErrors({ ...errors, debt: "" });
                }}
              />
              {errors.otherIncome && (
                <p className="f-12 text-danger">{errors.otherIncome}</p>
              )}
            </Col>

            <Col md={6}>
              {state.debt !== "" && state.debt > 0 && (
                <>
                  <Form.Label htmlFor="basic-url">{"Interest (%)"}</Form.Label>
                  <CurrencyFormat
                    className="form-control"
                    value={state.interest}
                    thousandSeparator={true}
                    onValueChange={(values) => {
                      const { value } = values;
                      setState({ ...state, interest: value });
                      setErrors({ ...errors, interest: "" });
                    }}
                  />
                  {errors.interest && (
                    <p className="f-12 text-danger">{errors.interest}</p>
                  )}
                </>
              )}
            </Col>
            <Col md={6}>
              <Form.Label htmlFor="basic-url">{"Celi ($CA)"}</Form.Label>
              <CurrencyFormat
                className="form-control"
                value={state.celi}
                thousandSeparator={true}
                onValueChange={(values) => {
                  const { value } = values;
                  setState({ ...state, celi: value });
                  setErrors({ ...errors, celi: "" });
                }}
              />
              {errors.celi && (
                <p className="f-12 text-danger">{errors.othecelirIncome}</p>
              )}
            </Col>
            <Col md={6}>
              {state.celi !== "" && state.celi > 0 && (
                <>
                  <Form.Label htmlFor="basic-url">{"Rate (%)"}</Form.Label>
                  <CurrencyFormat
                    className="form-control"
                    value={state.celiP}
                    thousandSeparator={true}
                    onValueChange={(values) => {
                      const { value } = values;
                      setState({ ...state, celiP: value });
                      setErrors({ ...errors, celiP: "" });
                    }}
                  />
                  {errors.celiP && (
                    <p className="f-12 text-danger">{errors.celiP}</p>
                  )}
                </>
              )}
            </Col>
            <Col md={6}>
              <Form.Label htmlFor="basic-url">{"RRSP ($CA)"}</Form.Label>
              <CurrencyFormat
                className="form-control"
                value={state.reer}
                thousandSeparator={true}
                onValueChange={(values) => {
                  const { value } = values;
                  setState({ ...state, reer: value });
                  setErrors({ ...errors, reer: "" });
                }}
              />
              {errors.reer && <p className="f-12 text-danger">{errors.reer}</p>}
            </Col>
            <Col md={6}>
              {state.reer !== "" && state.reer > 0 && (
                <>
                  <Form.Label htmlFor="basic-url">{"Rate (%)"}</Form.Label>
                  <CurrencyFormat
                    className="form-control"
                    value={state.reerP}
                    thousandSeparator={true}
                    onValueChange={(values) => {
                      const { value } = values;
                      setState({ ...state, reerP: value });
                      setErrors({ ...errors, reerP: "" });
                    }}
                  />
                  {errors.reerP && (
                    <p className="f-12 text-danger">{errors.reerP}</p>
                  )}
                </>
              )}
            </Col>
            <Col md={6}>
              <Form.Label htmlFor="basic-url">{"No RRSP ($CA)"}</Form.Label>
              <CurrencyFormat
                className="form-control"
                value={state.noReer}
                thousandSeparator={true}
                onValueChange={(values) => {
                  const { value } = values;
                  setState({ ...state, noReer: value });
                  setErrors({ ...errors, noReer: "" });
                }}
              />
              {errors.noReer && (
                <p className="f-12 text-danger">{errors.noReer}</p>
              )}
            </Col>
            <Col md={6}>
              {state.noReer !== "" && state.noReer > 0 && (
                <>
                  <Form.Label htmlFor="basic-url">{"Rate (%)"}</Form.Label>
                  <CurrencyFormat
                    className="form-control"
                    value={state.noReerP}
                    thousandSeparator={true}
                    onValueChange={(values) => {
                      const { value } = values;
                      setState({ ...state, noReerP: value });
                      setErrors({ ...errors, noReerP: "" });
                    }}
                  />
                  {errors.noReerP && (
                    <p className="f-12 text-danger">{errors.noReerP}</p>
                  )}
                </>
              )}
            </Col>
            <Col md={6}>
              <Form.Label htmlFor="basic-url">
                {"Enterprise Value ($CA)"}
              </Form.Label>
              <CurrencyFormat
                className="form-control"
                value={state.companyValue}
                thousandSeparator={true}
                onValueChange={(values) => {
                  const { value } = values;
                  setState({ ...state, companyValue: value });
                  setErrors({ ...errors, companyValue: "" });
                }}
              />
              {errors.companyValue && (
                <p className="f-12 text-danger">{errors.companyValue}</p>
              )}
            </Col>
            <Col md={6}>
              {state.companyValue !== "" && state.companyValue > 0 && (
                <>
                  <Form.Label htmlFor="basic-url">
                    {"Growth rate (%)"}
                  </Form.Label>
                  <CurrencyFormat
                    className="form-control"
                    value={state.companyValueP}
                    thousandSeparator={true}
                    onValueChange={(values) => {
                      const { value } = values;
                      setState({ ...state, companyValueP: value });
                      setErrors({ ...errors, companyValueP: "" });
                    }}
                  />
                  {errors.companyValueP && (
                    <p className="f-12 text-danger">{errors.companyValueP}</p>
                  )}
                </>
              )}
            </Col>
            <Col md={6}>
              <Form.Label htmlFor="basic-url">
                {"Management company ($CA)"}
              </Form.Label>
              <CurrencyFormat
                className="form-control"
                value={state.managementValue}
                thousandSeparator={true}
                onValueChange={(values) => {
                  const { value } = values;
                  setState({ ...state, managementValue: value });
                  setErrors({ ...errors, managementValue: "" });
                }}
              />
              {errors.managementValue && (
                <p className="f-12 text-danger">{errors.managementValue}</p>
              )}
            </Col>
            <Col md={6}>
              {state.cemanagementValueli !== "" && state.managementValue > 0 && (
                <>
                  <Form.Label htmlFor="basic-url">
                    {"Growth rate (%)"}
                  </Form.Label>
                  <CurrencyFormat
                    className="form-control"
                    value={state.managementValueP}
                    thousandSeparator={true}
                    onValueChange={(values) => {
                      const { value } = values;
                      setState({ ...state, managementValueP: value });
                      setErrors({ ...errors, managementValueP: "" });
                    }}
                  />
                  {errors.managementValueP && (
                    <p className="f-12 text-danger">
                      {errors.managementValueP}
                    </p>
                  )}
                </>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" disabled={loading}>
            {loading && (
              <Spinner
                style={{
                  marginRight: "5px",
                  marginBottom: "2px",
                }}
                size="sm"
                animation="border"
              />
            )}
            Edit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default FinancialStatementEditForm;
