import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Field from "./Field";

const ContactForm = () => {
  return (
    <Form>
      <Row>
        <Col md={6}>
          <Field id="name" label="Your name *" />
        </Col>
        <Col md={6}>
          <Field id="email" label="Your email *" type="email" />
        </Col>
        <Col md={12}>
          <Field id="subject" label="Subject *" />
        </Col>
        <Form.Group className="col-md-12" controlId="Message *">
          <Form.Label>Your message</Form.Label>
          <Form.Control as="textarea" rows={3} />
        </Form.Group>
      </Row>
      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>
  );
};

export default ContactForm;
