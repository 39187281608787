import React, { useContext } from "react";
import { Card } from "react-bootstrap";
import UserContext from "../../context/UserContext";
import CardBody from "../card/CardBody";
import RenderReports from "./RenderReports";

const Report = () => {
  const { user } = useContext(UserContext);

  return user.reportCompleted.length > 0 ? (
    <RenderReports reports={user.reportCompleted} />
  ) : (
    <div className="container mt-5 mb-2">
      <Card>
        <Card.Header>Reports list</Card.Header>
        <CardBody>
          <p>You have no report for the moment.</p>
        </CardBody>
      </Card>
    </div>
  );
};

export default Report;
