import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import Divider from "../components/Divider";
import Field from "../components/form/Field";
import Title from "../components/sign/Title";
import Mailer from "../services/Mailer";
import UserAPI from "../services/UserAPI";

const SignUpPage = ({ history }) => {
  const [user, setUser] = useState({
    firstName: "",
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({});

  // Gestion des champs
  const handleChange = ({ currentTarget }) => {
    const { value, name } = currentTarget;
    setUser({ ...user, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    const apiErrors = {};
    try {
      await UserAPI.create(user);
      toast.success("You are successfully registered, you can now login !");
      Mailer.sendMail("registration", {
        email: user.email,
        username: user.firstName + " " + user.lastName,
      });

      history.replace("/sign-in");
    } catch (error) {
      window.scrollTo(0, 0);
      toast.warning(" Please check the information provided !");
      setLoading(false);
      const { violations } = error.response.data;
      if (violations) {
        violations.forEach((violation) => {
          violation.propertyPath.split(".")[0] === "company"
            ? (apiErrors.company[violation.propertyPath.split(".")[1]] =
                violation.message)
            : (apiErrors[violation.propertyPath] = violation.message);
        });
        setErrors(apiErrors);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="bg-gradient-primary">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10 col-lg-12 col-md-9">
            <div className="card o-hidden border-0 shadow-lg my-100">
              <div className="card-body p-5">
                <Title title="Sign Up" />
                <Divider />
                <Form className="user" onSubmit={handleSubmit}>
                  <Row>
                    <Col md={6}>
                      <Field
                        id="firstName"
                        placeholder="First Name"
                        label="First Name *"
                        onChange={handleChange}
                        error={errors.firstName}
                      />
                    </Col>
                    <Col md={6}>
                      <Field
                        id="lastName"
                        placeholder="Last Name"
                        label="Name *"
                        onChange={handleChange}
                        error={errors.lastName}
                      />
                    </Col>
                    <Col md={12}>
                      <Field
                        id="email"
                        type="email"
                        placeholder="Enter email"
                        label="Email address *"
                        onChange={handleChange}
                        error={errors.email}
                      >
                        <Form.Text className="text-muted">
                          We'll never share your email with anyone else.
                        </Form.Text>
                      </Field>
                    </Col>
                    <Col md={12}>
                      <Field
                        id="password"
                        type="password"
                        placeholder="Password"
                        label="Password *"
                        onChange={handleChange}
                        error={errors.password}
                      />
                    </Col>
                    <Col md={12}>
                      <Field
                        id="confirmPassword"
                        type="password"
                        placeholder="Password"
                        label="Confirm password *"
                        onChange={handleChange}
                        error={errors.confirmPassword}
                      />
                    </Col>
                  </Row>
                  <Button variant="primary" type="submit" disabled={loading}>
                    {loading && (
                      <Spinner
                        style={{
                          marginRight: "5px",
                          marginBottom: "2px",
                        }}
                        size="sm"
                        animation="border"
                      />
                    )}
                    Submit
                  </Button>
                </Form>
                <Divider />
                <div className="text-center">
                  <NavLink className="small" to="">
                    Forgot Password?
                  </NavLink>
                </div>
                <div className="text-center">
                  <NavLink className="small" to="/sign-in">
                    Already have an account? Login!
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
