import React from "react";
import { NavLink } from "react-bootstrap";
import ContactForm from "../components/form/ContactForm";

const HomePage = () => {
  return (
    <div className="home-page">
      <div className="bg-home-image">
        <NavLink
          className="btn btn-primary btn-home rounded-pill mt-5 p-3 d-inline"
          href="#mission"
        >
          Learn More
        </NavLink>
        <div className="bg-circle-1 bg-circle"></div>
        <div className="bg-circle-2 bg-circle"></div>
        <div className="bg-circle-3 bg-circle"></div>
        <div className="bg-circle-4 bg-circle"></div>
      </div>
      <blockquote className="blockquote text-center">
        <h1 className="mb-0 mr-3">
          A goal without a plan is just a wish,{" "}
          <span>Antoine de saint-Exupéry</span>
        </h1>
      </blockquote>
      <div className="bg-grey" id="mission">
        <div className="section container">
          <h2 className="title-h">
            About <span>us</span>
          </h2>
          <p className="title-p">
            Planning is bringing the future into the present so that you can do
            something about it now, Alan Lakein
          </p>
          <p className="text-justify">
            A good plan can make dreams come true. InomSimultion is an
            innovative platform to help the financial analyst and real estate
            adviser to prepare the best strategy for offering you a wonderful
            retirement. We support your real estate investor, with saving time
            and money to simulate different situations in an effective time. If
            you want to become rich during your sleep,{" "}
            <NavLink className="p-0 d-inline" href="/#/sign-up">
              <u>register here</u>
            </NavLink>
            .
          </p>
        </div>
      </div>
      <div id="contact">
        <div className="section container">
          <h2 className="title-h">
            Contact <span>us</span>
          </h2>
          <p className="title-p">
            For all enquiries, please email us using the form below.
          </p>
          <ContactForm />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
