import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { toast } from "react-toastify";
import UserContext from "../../context/UserContext";
import UserAPI from "../../services/UserAPI";

const FinancialStatementForm = () => {
  const { user, setUser } = useContext(UserContext);
  const [state, setState] = useState({
    investedAmount: "",
    income: 0,
    otherIncome: 0,
    debt: 0,
    interest: 0,
    celi: 0,
    celiP: 0,
    reer: 0,
    reerP: 0,
    noReer: 0,
    noReerP: 0,
    companyValue: 0,
    companyValueP: 0,
    managementValue: 0,
    managementValueP: 0,
  });

  const [loading, setLoading] = useState(false);

  // Gestion des erreurs
  const [errors, setErrors] = useState({});

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    const apiErrors = {};
    try {
      const { financialStatements } = await UserAPI.add_statement(
        user.id,
        state
      );
      toast.success(
        "You have successfully filled in your information, a consultant will contact you shortly !"
      );
      setUser({ ...user, financialStatements });
    } catch (error) {
      window.scrollTo(0, 0);
      toast.warning(" Please check the information provided !");
      setLoading(false);
      const { violations } = error.response.data;
      if (violations) {
        violations.forEach((violation) => {
          apiErrors[violation.propertyPath.split(".")[1]] = violation.message;
        });
        setErrors(apiErrors);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col md={12}>
          <Form.Label htmlFor="basic-url">{"Invested Amount"}</Form.Label>
          <CurrencyFormat
            className="form-control"
            value={state.investedAmount}
            thousandSeparator={true}
            suffix={" CA$"}
            onValueChange={(values) => {
              const { value } = values;
              setState({ ...state, investedAmount: value });
              setErrors({ ...errors, investedAmount: "" });
            }}
          />
          {errors.investedAmount && (
            <p className="f-12 text-danger">{errors.investedAmount}</p>
          )}
        </Col>
        <Col md={6}>
          <Form.Label htmlFor="basic-url">{"Income"}</Form.Label>
          <CurrencyFormat
            className="form-control"
            value={state.income}
            thousandSeparator={true}
            suffix={" CA$"}
            onValueChange={(values) => {
              const { value } = values;
              setState({ ...state, income: value });
              setErrors({ ...errors, income: "" });
            }}
          />
          {errors.income && <p className="f-12 text-danger">{errors.income}</p>}
        </Col>
        <Col md={6}>
          <Form.Label htmlFor="basic-url">{"Other Income"}</Form.Label>
          <CurrencyFormat
            className="form-control"
            value={state.otherIncome}
            thousandSeparator={true}
            suffix={" CA$"}
            onValueChange={(values) => {
              const { value } = values;
              setState({ ...state, otherIncome: value });
              setErrors({ ...errors, otherIncome: "" });
            }}
          />
          {errors.otherIncome && (
            <p className="f-12 text-danger">{errors.otherIncome}</p>
          )}
        </Col>
        <Col md={6}>
          <Form.Label htmlFor="basic-url">{"Debt"}</Form.Label>
          <CurrencyFormat
            className="form-control"
            value={state.debt}
            thousandSeparator={true}
            suffix={" CA$"}
            onValueChange={(values) => {
              const { value } = values;
              setState({ ...state, debt: value });
              setErrors({ ...errors, debt: "" });
            }}
          />
          {errors.otherIncome && (
            <p className="f-12 text-danger">{errors.otherIncome}</p>
          )}
        </Col>

        <Col md={6}>
          {state.debt !== "" && state.debt > 0 && (
            <>
              <Form.Label htmlFor="basic-url">{"Interest"}</Form.Label>
              <CurrencyFormat
                className="form-control"
                value={state.interest}
                thousandSeparator={true}
                suffix={" %"}
                onValueChange={(values) => {
                  const { value } = values;
                  setState({ ...state, interest: value });
                  setErrors({ ...errors, interest: "" });
                }}
              />
              {errors.interest && (
                <p className="f-12 text-danger">{errors.interest}</p>
              )}
            </>
          )}
        </Col>
        <Col md={6}>
          <Form.Label htmlFor="basic-url">{"Celi"}</Form.Label>
          <CurrencyFormat
            className="form-control"
            value={state.celi}
            thousandSeparator={true}
            suffix={" CA$"}
            onValueChange={(values) => {
              const { value } = values;
              setState({ ...state, celi: value });
              setErrors({ ...errors, celi: "" });
            }}
          />
          {errors.celi && (
            <p className="f-12 text-danger">{errors.othecelirIncome}</p>
          )}
        </Col>
        <Col md={6}>
          {state.celi !== "" && state.celi > 0 && (
            <>
              <Form.Label htmlFor="basic-url">{"Rate"}</Form.Label>
              <CurrencyFormat
                className="form-control"
                value={state.celiP}
                thousandSeparator={true}
                suffix={" %"}
                onValueChange={(values) => {
                  const { value } = values;
                  setState({ ...state, celiP: value });
                  setErrors({ ...errors, celiP: "" });
                }}
              />
              {errors.celiP && (
                <p className="f-12 text-danger">{errors.celiP}</p>
              )}
            </>
          )}
        </Col>
        <Col md={6}>
          <Form.Label htmlFor="basic-url">{"RRSP"}</Form.Label>
          <CurrencyFormat
            className="form-control"
            value={state.reer}
            thousandSeparator={true}
            suffix={" CA$"}
            onValueChange={(values) => {
              const { value } = values;
              setState({ ...state, reer: value });
              setErrors({ ...errors, reer: "" });
            }}
          />
          {errors.reer && <p className="f-12 text-danger">{errors.reer}</p>}
        </Col>
        <Col md={6}>
          {state.reer !== "" && state.reer > 0 && (
            <>
              <Form.Label htmlFor="basic-url">{"Rate"}</Form.Label>
              <CurrencyFormat
                className="form-control"
                value={state.reerP}
                thousandSeparator={true}
                suffix={" %"}
                onValueChange={(values) => {
                  const { value } = values;
                  setState({ ...state, reerP: value });
                  setErrors({ ...errors, reerP: "" });
                }}
              />
              {errors.reerP && (
                <p className="f-12 text-danger">{errors.reerP}</p>
              )}
            </>
          )}
        </Col>
        <Col md={6}>
          <Form.Label htmlFor="basic-url">{"No RRSP"}</Form.Label>
          <CurrencyFormat
            className="form-control"
            value={state.noReer}
            thousandSeparator={true}
            suffix={" CA$"}
            onValueChange={(values) => {
              const { value } = values;
              setState({ ...state, noReer: value });
              setErrors({ ...errors, noReer: "" });
            }}
          />
          {errors.noReer && <p className="f-12 text-danger">{errors.noReer}</p>}
        </Col>
        <Col md={6}>
          {state.noReer !== "" && state.noReer > 0 && (
            <>
              <Form.Label htmlFor="basic-url">{"Rate"}</Form.Label>
              <CurrencyFormat
                className="form-control"
                value={state.noReerP}
                thousandSeparator={true}
                suffix={" %"}
                onValueChange={(values) => {
                  const { value } = values;
                  setState({ ...state, noReerP: value });
                  setErrors({ ...errors, noReerP: "" });
                }}
              />
              {errors.noReerP && (
                <p className="f-12 text-danger">{errors.noReerP}</p>
              )}
            </>
          )}
        </Col>
        <Col md={6}>
          <Form.Label htmlFor="basic-url">{"Enterprise Value"}</Form.Label>
          <CurrencyFormat
            className="form-control"
            value={state.companyValue}
            thousandSeparator={true}
            suffix={" CA$"}
            onValueChange={(values) => {
              const { value } = values;
              setState({ ...state, companyValue: value });
              setErrors({ ...errors, companyValue: "" });
            }}
          />
          {errors.companyValue && (
            <p className="f-12 text-danger">{errors.companyValue}</p>
          )}
        </Col>
        <Col md={6}>
          {state.companyValue !== "" && state.companyValue > 0 && (
            <>
              <Form.Label htmlFor="basic-url">{"Growth rate"}</Form.Label>
              <CurrencyFormat
                className="form-control"
                value={state.companyValueP}
                thousandSeparator={true}
                suffix={" %"}
                onValueChange={(values) => {
                  const { value } = values;
                  setState({ ...state, companyValueP: value });
                  setErrors({ ...errors, companyValueP: "" });
                }}
              />
              {errors.companyValueP && (
                <p className="f-12 text-danger">{errors.companyValueP}</p>
              )}
            </>
          )}
        </Col>
        <Col md={6}>
          <Form.Label htmlFor="basic-url">{"Management company"}</Form.Label>
          <CurrencyFormat
            className="form-control"
            value={state.managementValue}
            thousandSeparator={true}
            suffix={" CA$"}
            onValueChange={(values) => {
              const { value } = values;
              setState({ ...state, managementValue: value });
              setErrors({ ...errors, managementValue: "" });
            }}
          />
          {errors.managementValue && (
            <p className="f-12 text-danger">{errors.managementValue}</p>
          )}
        </Col>
        <Col md={6}>
          {state.cemanagementValueli !== "" && state.managementValue > 0 && (
            <>
              <Form.Label htmlFor="basic-url">{"Growth rate"}</Form.Label>
              <CurrencyFormat
                className="form-control"
                value={state.managementValueP}
                thousandSeparator={true}
                suffix={" %"}
                onValueChange={(values) => {
                  const { value } = values;
                  setState({ ...state, managementValueP: value });
                  setErrors({ ...errors, managementValueP: "" });
                }}
              />
              {errors.managementValueP && (
                <p className="f-12 text-danger">{errors.managementValueP}</p>
              )}
            </>
          )}
        </Col>
      </Row>
      <Button
        variant="primary"
        type="submit"
        className="mt-3"
        disabled={loading}
      >
        {loading && (
          <Spinner
            style={{
              marginRight: "5px",
              marginBottom: "2px",
            }}
            size="sm"
            animation="border"
          />
        )}
        Save
      </Button>
    </Form>
  );
};

export default FinancialStatementForm;
