import { URL } from "../config";

function sendMail(action, object) {
  window.fetch(URL + "/send/" + action, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(object),
  });
}

export default {
  sendMail,
};
