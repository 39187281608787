/* eslint-disable import/no-anonymous-default-export */
import Axios from "axios";
import jwtDecode from "jwt-decode";
import { API_URL, CURRENT_USER } from "../config";

// Déconnexion (suppression du token du localStorage et sur Axios)
function logout() {
  window.localStorage.removeItem("authToken");
  delete Axios.defaults.headers["Authorization"];
}

function setAxiosToken(token) {
  Axios.defaults.headers["Authorization"] = "Bearer " + token;
}

/**
 *Requête HTTP d'authentifications et stockage du token dans le local storage et axios
 * @param {object} creadentials
 */
function authenticate(creadentials) {
  return Axios.post(API_URL + "ino/authentication_token", creadentials)
    .then((response) => response.data.token)
    .then((token) => {
      // On stock le token dans le localstorage
      window.localStorage.setItem("authToken", token);

      // On prévient Axios qu'on a maintenant un header par défaut sur toutes nos factures requestes HTTP
      setAxiosToken(token);

      return true;
    });
}

// Mise en place lors du chargement de l'application
function setup() {
  // Je récupère mon token
  const token = window.localStorage.getItem("authToken");
  if (token) {
    // Vérifier si le token est valide
    const { exp: expiration } = jwtDecode(token);
    if (expiration * 1000 > new Date().getTime()) {
      setAxiosToken(token);
    }
  }
}

// Permet de savoir si on est authentifié ou pas
function isAuthenticated() {
  // Je récupère mon token
  const token = window.localStorage.getItem("authToken");

  // Vérifier si le token est valide
  if (token) {
    const { exp: expiration } = jwtDecode(token);
    if (expiration * 1000 > new Date().getTime()) {
      setAxiosToken(token);
      return true;
    }
    return false;
  }
  return false;
}

// Permet de récupérer le nom d'utilisateur
function currentUser() {
  return Axios.get(CURRENT_USER).then(
    (response) => response.data["hydra:member"][0]
  );
}

export default {
  authenticate,
  logout,
  setup,
  isAuthenticated,
  currentUser,
};
