import React from "react";

const Title = ({ title }) => {
  return (
    <div className="text-center">
      <h1 className="h4 text-gray-900 mb-4">{title}</h1>
    </div>
  );
};

export default Title;
