import React from "react";
import { Form } from "react-bootstrap";

const Field = ({
  id,
  label,
  value,
  type = "text",
  placeholder = "",
  onChange,
  error = "",
  children,
}) => {
  return (
    <Form.Group controlId={id}>
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        name={id}
        value={value}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        isInvalid={!!error}
      />
      {children}
      {error && (
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

export default Field;
