import React, { useContext } from "react";
import { Button, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import AuthContext from "../../context/AuthContext";
import AuthAPI from "../../services/AuthAPI";

const Header = ({ history }) => {
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);

  const handleLogout = () => {
    AuthAPI.logout();
    setIsAuthenticated(false);
    toast.success("You are successfully logged out !");
    history.replace("/");
  };

  return (
    <Navbar
      bg="primary"
      expand="lg"
      variant="dark"
      fixed="top"
      className="px-3"
    >
      <Navbar.Brand href="#">INOSIMULATION</Navbar.Brand>
      <Navbar.Toggle aria-controls="navbar-ino" />
      <Navbar.Collapse id="navbar-ino" className="justify-content-end">
        <Nav className="ml-auto">
          {!isAuthenticated ? (
            <>
              <Nav.Link href="#/sign-in">Start</Nav.Link>
              <NavDropdown title="Home" id="nav-ino-dropdown">
                <NavDropdown.Item href="#mission">Mission</NavDropdown.Item>
                <NavDropdown.Item href="#contact">Contact</NavDropdown.Item>
              </NavDropdown>
            </>
          ) : (
            <Button onClick={handleLogout}>Logout</Button>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
