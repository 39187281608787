import Axios from "axios";
import { FINANCIALSTATEMENTS_URL } from "../config";

function edit(id, financialStatements) {
  let values = {
    investedAmount: financialStatements.investedAmount
      ? parseFloat(financialStatements.investedAmount)
      : null,
    income: financialStatements.income
      ? parseFloat(financialStatements.income)
      : 0,
    otherIncome: financialStatements.otherIncome
      ? parseFloat(financialStatements.otherIncome)
      : 0,
    debt: financialStatements.debt ? parseFloat(financialStatements.debt) : 0,
    interest: financialStatements.interest
      ? parseFloat(financialStatements.interest)
      : 0,
    celi: financialStatements.celi ? parseFloat(financialStatements.celi) : 0,
    celiP: financialStatements.celiP
      ? parseFloat(financialStatements.celiP)
      : 0,
    reer: financialStatements.reer ? parseFloat(financialStatements.reer) : 0,
    reerP: financialStatements.reerP
      ? parseFloat(financialStatements.reerP)
      : 0,
    noReer: financialStatements.noReer
      ? parseFloat(financialStatements.noReer)
      : 0,
    noReerP: financialStatements.noReerP
      ? parseFloat(financialStatements.noReerP)
      : 0,
    companyValue: financialStatements.companyValue
      ? parseFloat(financialStatements.companyValue)
      : 0,
    companyValueP: financialStatements.companyValueP
      ? parseFloat(financialStatements.companyValueP)
      : 0,
    managementValue: financialStatements.managementValue
      ? parseFloat(financialStatements.managementValue)
      : 0,
    managementValueP: financialStatements.managementValueP
      ? parseFloat(financialStatements.managementValueP)
      : 0,
  };

  return Axios.put(FINANCIALSTATEMENTS_URL + "/" + id, values).then(
    (response) => response.data
  );
}

function deleteStatement(id) {
  return Axios.delete(FINANCIALSTATEMENTS_URL + "/" + id).then(
    (response) => response.data
  );
}

export default {
  edit,
  delete: deleteStatement,
};
