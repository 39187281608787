import React, { useContext, useState } from "react";
import { Accordion, Button, Card, Col, ListGroup, Row } from "react-bootstrap";
import UserContext from "../../context/UserContext";
import { currencyFormat, dateFormat } from "../../functions/Format";
import PropertyDelete from "./PropertyDelete";
import PropertyEdit from "./PropertyEdit";

const RenderProperties = () => {
  const { user } = useContext(UserContext);

  const [show, setShow] = useState({ name: null, id: null });

  const handleShow = (name, id) => setShow({ name, id });
  const renderProperty = (property, i) => {
    return (
      <Card key={i} className="mt-3">
        <Accordion.Item eventKey={i.toString()}>
          <Accordion.Header>
            <div>Property name: {property.name}</div>
            <div className="f-12 px-5">
              {property.editedAt
                ? " edited on " + dateFormat(property.createdAt)
                : " added on " + dateFormat(property.createdAt)}
            </div>
          </Accordion.Header>
          <div className="text-end">
            <Button
              size="sm"
              variant="link"
              className="text-primary f-12"
              onClick={() => handleShow("edit", property)}
            >
              Edit
            </Button>
            <Button
              size="sm"
              variant="link"
              className="text-danger f-12"
              onClick={() => handleShow("delete", property["@id"])}
            >
              Delete
            </Button>
          </div>
          <Accordion.Body>
            <ListGroup>
              <ListGroup.Item>
                <Row>
                  <Col>
                    <p className="m-0">Purchasing price:</p>
                    <p className="m-0">
                      {currencyFormat(property.marketValue)}
                    </p>
                  </Col>
                  <Col>
                    <p className="m-0">Mortgage:</p>
                    <p className="m-0">{currencyFormat(property.mortgage)}</p>
                  </Col>
                  <Col>
                    <p className="m-0">Interest rate:</p>
                    <p className="m-0">{property.interest} %</p>
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>
                    <p className="m-0">Amortization:</p>
                    <p className="m-0">
                      {property.amortization} year
                      {property.amortization > 1 && "s"}
                    </p>
                  </Col>
                  <Col>
                    <p className="m-0">Income:</p>
                    <p className="m-0">{currencyFormat(property.income)}</p>
                  </Col>
                  <Col>
                    <p className="m-0">Expenses:</p>
                    <p className="m-0">
                      {currencyFormat(property.operatingExpense)}
                    </p>
                  </Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>
          </Accordion.Body>
        </Accordion.Item>
        <PropertyDelete
          show={show.name === "delete"}
          setShow={setShow}
          id={show.id}
        />
        {show.name === "edit" && (
          <PropertyEdit
            show={show.name === "edit"}
            setShow={setShow}
            data={show.id}
            id={show.id && show.id["@id"] && show.id["@id"]}
          />
        )}
      </Card>
    );
  };
  return (
    <Accordion defaultActiveKey="0">
      {user.properties.map(renderProperty)}
    </Accordion>
  );
};

export default RenderProperties;
