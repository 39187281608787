import React, { useEffect, useState } from "react";
import { HashRouter, Route, Switch, withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./components/footer/Footer";
import Header from "./components/navbar/Header";
import PrivateRoute from "./components/PrivateRoute";
import AuthContext from "./context/AuthContext";
import UserContext from "./context/UserContext";
import DashboardPage from "./pages/DashboardPage";
import HomePage from "./pages/HomePage";
import SignInPage from "./pages/SignInPage";
import SignUpPage from "./pages/SignUpPage";
import AuthAPI from "./services/AuthAPI";
import "./scss/styles.scss";

AuthAPI.setup();

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    AuthAPI.isAuthenticated()
  );

  const [user, setUser] = useState(null);

  const fetchUser = async () => {
    try {
      setUser(await AuthAPI.currentUser());
    } catch (error) {
      setIsAuthenticated(false);
    }
  };

  useEffect(() => {
    isAuthenticated && fetchUser();
  }, [isAuthenticated]);

  const NavbarWithRouter = withRouter(Header);

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
      <UserContext.Provider value={{ user, setUser }}>
        <HashRouter>
          <NavbarWithRouter />
          {!isAuthenticated ? (
            <Switch>
              <Route path="/sign-in" component={SignInPage} />
              <Route path="/sign-up" component={SignUpPage} />
              <Route path="/" component={HomePage} />
            </Switch>
          ) : (
            <Switch>
              <PrivateRoute path="/" component={DashboardPage} />
            </Switch>
          )}
          <Footer />
          <ToastContainer />
        </HashRouter>
      </UserContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
