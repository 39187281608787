import React from "react";
import { Spinner } from "react-bootstrap";

const Loading = () => {
  return (
    <div className="row align-items-center absolute middle">
      <Spinner className="mr-3" animation="border" variant="primary" />
      Please wait, we are retrieving your data.
    </div>
  );
};

export default Loading;
