import React, { useContext } from "react";
import UserContext from "../../context/UserContext";
import Card from "../card/Card";
import CardBody from "../card/CardBody";
import Divider from "../Divider";
import FinancialStatementForm from "./FinancialStatementForm";
import RenderFinancialStatment from "./RenderFinancialStatement";

const FinancialStatement = () => {
  const { user } = useContext(UserContext);
  return user.financialStatements ? (
    <RenderFinancialStatment />
  ) : (
    <Card>
      <CardBody>
        <h3 className="text-center mb-0">
          Please, complete your financial statement
        </h3>
        <Divider />
        <p>
          In order to personalize your investment, advisors need your personal
          and financial information, as well as that of your partner.
        </p>
        <p>
          This information helps advisors generate a personalized report that
          will give you a 360-degree view of the evolution of your investment
          and the security of your retirement income.
        </p>
        <Divider />
        <FinancialStatementForm />
      </CardBody>
    </Card>
  );
};

export default FinancialStatement;
