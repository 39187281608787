import Axios from "axios";
import { USERS_API } from "../config";

function create(user) {
  return Axios.post(USERS_API, user);
}

function add_statement(id, financialStatements) {
  let values = {
    investedAmount: financialStatements.investedAmount
      ? parseFloat(financialStatements.investedAmount)
      : null,
    income: financialStatements.income
      ? parseFloat(financialStatements.income)
      : 0,
    otherIncome: financialStatements.otherIncome
      ? parseFloat(financialStatements.otherIncome)
      : 0,
    debt: financialStatements.debt ? parseFloat(financialStatements.debt) : 0,
    interest: financialStatements.interest
      ? parseFloat(financialStatements.interest)
      : 0,
    celi: financialStatements.celi ? parseFloat(financialStatements.celi) : 0,
    celiP: financialStatements.celiP
      ? parseFloat(financialStatements.celiP)
      : 0,
    reer: financialStatements.reer ? parseFloat(financialStatements.reer) : 0,
    reerP: financialStatements.reerP
      ? parseFloat(financialStatements.reerP)
      : 0,
    noReer: financialStatements.noReer
      ? parseFloat(financialStatements.noReer)
      : 0,
    noReerP: financialStatements.noReerP
      ? parseFloat(financialStatements.noReerP)
      : 0,
    companyValue: financialStatements.companyValue
      ? parseFloat(financialStatements.companyValue)
      : 0,
    companyValueP: financialStatements.companyValueP
      ? parseFloat(financialStatements.companyValueP)
      : 0,
    managementValue: financialStatements.managementValue
      ? parseFloat(financialStatements.managementValue)
      : 0,
    managementValueP: financialStatements.managementValueP
      ? parseFloat(financialStatements.managementValueP)
      : 0,
  };
  return Axios.put(USERS_API + "/add_statement/" + id, {
    financialStatements: values,
  }).then((response) => response.data);
}

function add_properties(id, property, oldProperties) {
  let values = {
    name: property.name,
    marketValue: property.marketValue ? parseFloat(property.marketValue) : null,
    income: property.income ? parseFloat(property.income) : null,
    mortgage: property.mortgage ? parseFloat(property.mortgage) : null,
    interest: property.interest ? parseFloat(property.interest) : null,
    amortization: property.amortization
      ? parseFloat(property.amortization)
      : null,
    operatingExpense: property.operatingExpense
      ? parseFloat(property.operatingExpense)
      : null,
  };
  oldProperties.forEach((element) => {
    delete element.id;
  });
  let newProperties = oldProperties.concat(values);
  return Axios.put(USERS_API + "/add_property/" + id, {
    properties: newProperties,
  }).then((response) => response.data);
}

function delete_properties(id, newProperties) {
  newProperties.forEach((element) => {
    delete element.id;
  });
  return Axios.put(USERS_API + "/add_property/" + id, {
    properties: newProperties,
  }).then((response) => response.data);
}

export default {
  create,
  add_statement,
  add_properties,
  delete_properties,
};
