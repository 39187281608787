import React, { useContext, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import UserContext from "../../context/UserContext";
import FinancialStatementAPI from "../../services/FinancialStatementAPI";

const FinancialStatementDelete = ({ show, setShow, id }) => {
  const { user, setUser } = useContext(UserContext);

  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    try {
      await FinancialStatementAPI.delete(id);
      toast.success("You have successfully deleted your information!");
      setShow(false);
      setLoading(false);
      setUser({ ...user, financialStatements: undefined, properties: [] });
    } catch (error) {
      toast.warning("Something went wrong, please try again!");
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Delete financial statement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You will delete your financial information and associated properties
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" type="submit" disabled={loading}>
            {loading && (
              <Spinner
                style={{
                  marginRight: "5px",
                  marginBottom: "2px",
                }}
                size="sm"
                animation="border"
              />
            )}
            Delete
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default FinancialStatementDelete;
