import React, { useContext, useState } from "react";
import { Button, Card, Col, ListGroup, Row } from "react-bootstrap";
import UserContext from "../../context/UserContext";
import { currencyFormat, dateFormat } from "../../functions/Format";
import FinancialStatementDelete from "./FinancialStatementDelete";
import FinancialStatementEditForm from "./FinancialStatementEditForm";
import PropertyForm from "./PropertyForm";
import RenderProperties from "./RenderProperties";

const RenderFinancialStatment = () => {
  const { user } = useContext(UserContext);
  const [show, setShow] = useState(false);

  const handleShow = (name) => setShow(name);
  return (
    <div className="container mt-5 mb-2">
      <Card>
        <Card.Header>
          <Row className="align-items-center">
            <Col md={6}>
              Financial statement completed on{" "}
              {dateFormat(
                user.financialStatements.editedAt
                  ? user.financialStatements.editedAt
                  : user.financialStatements.createdAt
              )}
            </Col>
            <Col md={6} className="text-align-right">
              <Button
                className="mx-1"
                variant="primary"
                size="sm"
                onClick={() => handleShow("property")}
              >
                Add Property
              </Button>
              <Button
                className="mx-1"
                variant="success"
                size="sm"
                onClick={() => handleShow("edit")}
              >
                Edit
              </Button>
              <Button
                className="mx-1"
                variant="danger"
                size="sm"
                onClick={() => handleShow("delete")}
              >
                Delete
              </Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <Row>
                <Col>
                  <p className="m-0">
                    Initial investment amount:{" "}
                    {currencyFormat(user.financialStatements.investedAmount)}
                  </p>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>
                  <p className="m-0">
                    Income: {currencyFormat(user.financialStatements.income)}
                  </p>
                </Col>
                <Col>
                  <p className="m-0">
                    Other Incomes:{" "}
                    {currencyFormat(user.financialStatements.otherIncome)}
                  </p>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>
                  <p className="m-0">
                    Debt: {currencyFormat(user.financialStatements.debt)}
                  </p>
                  <p className="m-0">
                    Interest rate: {user.financialStatements.interest || 0} %
                  </p>
                </Col>
                <Col>
                  <p className="m-0">
                    TFSA: {currencyFormat(user.financialStatements.celi)}
                  </p>
                  <p className="m-0">
                    Rate: {user.financialStatements.celiP || 0} %
                  </p>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>
                  <p className="m-0">
                    RRSP: {currencyFormat(user.financialStatements.reer)}
                  </p>
                  <p className="m-0">
                    Rate: {user.financialStatements.reerP || 0} %
                  </p>
                </Col>
                <Col>
                  <p className="m-0">
                    No RRSP: {currencyFormat(user.financialStatements.noReer)}
                  </p>
                  <p className="m-0">
                    No Reer Performance: {user.financialStatements.noReerP || 0}{" "}
                    %
                  </p>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>
                  <p className="m-0">
                    Enterprise Value:{" "}
                    {currencyFormat(user.financialStatements.companyValue)}
                  </p>
                  <p className="m-0">
                    Growth rate: {user.financialStatements.companyValueP || 0} %
                  </p>
                </Col>
                <Col>
                  <p className="m-0">
                    Management company:{" "}
                    {currencyFormat(user.financialStatements.managementValue)}
                  </p>
                  <p className="m-0">
                    Growth rate:{" "}
                    {user.financialStatements.managementValueP || 0} %
                  </p>
                </Col>
              </Row>
            </ListGroup.Item>
          </ListGroup>
        </Card.Body>
      </Card>
      <PropertyForm show={show === "property"} setShow={setShow} />
      <FinancialStatementEditForm
        show={show === "edit"}
        setShow={setShow}
        data={user.financialStatements}
      />
      <FinancialStatementDelete
        show={show === "delete"}
        setShow={setShow}
        id={user.financialStatements.id}
      />
      {user.properties.length > 0 ? (
        <>
          <RenderProperties />
        </>
      ) : (
        <Card.Text className="mt-3 text-center">
          The customer has no properties associated with his financial
          statement.
        </Card.Text>
      )}
    </div>
  );
};

export default RenderFinancialStatment;
